import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { constants } from '../../helpers/constants';
//import { ToasterService } from 'src/ej-shared/services/toastr.service';
//import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { environment, GoogleCaptcha } from 'src/environments/environment';
export class TenantHomeComponent {
    constructor(authService, router, route, tenantHomeService, cdRef, titleService, storageService, http, sharedService, globalService) {
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.tenantHomeService = tenantHomeService;
        this.cdRef = cdRef;
        this.titleService = titleService;
        this.storageService = storageService;
        this.http = http;
        this.sharedService = sharedService;
        this.globalService = globalService;
        this.homeViewModel = {};
        this.ejLogoSrc = "assets/media/logos/ej_logo.png";
        this.tenantLogoSrc = "";
        this.isAdmin = true;
        this.enquiryModel = {};
        this.saveEnquiryBtnDisabled = false;
        this.errorRecaptchaRender = false;
        this.gRecaptchaResponse = "";
        this.phoneNumberError = false;
        this.phoneCountryCodeError = false;
        this.phoneNumberLengthError = false;
        this.loading = false;
        this.lodingTenantInfo = true;
        this.loadSchoolInfo = true;
        this.captchaSiteKey = GoogleCaptcha.site_key;
        this.showSplashScreen = true;
        this.showMyHome = false;
        this.showNotebookCourse = false;
        this.notebookCourse = [
            { name: "1" },
            { name: "2" },
            { name: "3" },
            { name: "4" },
            { name: "5" },
            { name: "6" },
            { name: "7" },
            { name: "8" },
            { name: "9" },
            { name: "10" }
        ];
        this.notebookSignUpUrl = "https://notebook.edujournal.com/register-signup";
        this.notebookSignInUrl = "https://notebook.edujournal.com/auth/login";
        this.agentCode = "A000010";
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.titleService.setTitle('eduJournal | Home');
            this.setMindVaultBranding();
            yield this.authService.getTenantDetails().then((res) => {
                this.trainerType = res.trainerType;
                if (res.trainerType == 1 || res.trainerType == 3) {
                    this.detectChanges();
                    return this.authService.login();
                }
            });
            if (constants.admin_url.toLowerCase() == constants.host_url.toLowerCase()
                || environment.commonLoginUrl.toLowerCase() == constants.host_url.toLowerCase()
                || this.sharedService.isMember()
                || this.sharedService.isRedirectUrl()) {
                this.authService.login();
            }
            window.scrollTo(0, 0);
            this.loadingCountryCodes = true;
            yield this.getCountryCodes().then(res => {
                this.storageService.setCountryCodes(res);
                this.loadingCountryCodes = false;
            });
            yield this.globalService.setCurrency(null);
            yield this.globalService.setInternationalCurrency(null);
            this.isLoggedIn = yield this.authService.isLoggedin();
            if (window.location.origin.replace('/client', '').toLowerCase() == constants.admin_url.replace('/client', '').toLowerCase()) {
                this.isAdmin = true;
            }
            else {
                this.isAdmin = false;
            }
            this.isMember = this.sharedService.isMember();
            if (!this.isAdmin && !this.isMember && !this.sharedService.isRedirectUrl()) {
                yield this.getHomeViewModel();
                this.checkIfShowNotebookCourse();
            }
            this.validatePhoneNumber();
            this.checkDisplayMyHome();
        });
    }
    checkDisplayMyHome() {
        this.showMyHome = (!this.isAdmin && !this.isMember && (this.trainerType == 2));
        //// Disable My Home for Training Inst
        //this.showMyHome = false;
        this.showSplashScreen = !this.showMyHome;
    }
    login() {
        this.authService.login();
    }
    gotoApplication() {
        this.authService.login();
    }
    getHomeViewModel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.tenantHomeService.getHomeViewModel().then(data => {
                this.homeViewModel = data;
                this.setTenantImgSrc();
                this.lodingTenantInfo = false;
                this.detectChanges();
            });
        });
    }
    setTenantImgSrc() {
        //// Logo update to header disabled for now
        // if (this.homeViewModel && this.homeViewModel.tenantLogo
        //   && this.homeViewModel.tenantLogo.logoRelativePath != null && this.homeViewModel.tenantLogo.logoRelativePath != ""
        //   && this.homeViewModel.tenantLogo.logoName != null && this.homeViewModel.tenantLogo.logoName != "") {
        //   this.tenantLogoSrc = this.homeViewModel.tenantLogo.logoRelativePath + '\\60x60\\' + this.homeViewModel.tenantLogo.logoName;
        // }
        // else {
        //   this.tenantLogoSrc = this.ejLogoSrc;
        // }
        this.tenantLogoSrc = this.ejLogoSrc;
        this.detectChanges();
    }
    courseNav(courseId, wpPostUrl) {
        // disabled for now
        //this.router.navigate(['/courseenquiry', courseId]);
        if (wpPostUrl) {
            window.open(wpPostUrl);
        }
    }
    saveEnquiryEvent(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.validateGRrecaptcha() && !this.phoneNumberLengthError) {
                this.saveEnquiryBtnDisabled = true;
                yield this.saveEnquiry(form);
            }
        });
    }
    saveEnquiry(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.detectChanges();
            this.enquiryModel.gRecaptchaResponse = this.gRecaptchaResponse;
            yield this.tenantHomeService.saveEnquiry(this.enquiryModel).then(response => {
                this.loading = false;
                if (response.status) {
                    //this.toastr.success(response.information);
                    Swal.fire(response.information);
                    form.resetForm();
                    this.saveEnquiryBtnDisabled = false;
                    this.recaptchaRef.reset();
                    this.enquiryModel.mobile = "";
                }
                else {
                    //this.toastr.error(response.information);
                    Swal.fire(response.information);
                    this.saveEnquiryBtnDisabled = false;
                }
            });
        });
    }
    ;
    validateGRrecaptcha() {
        //recaptcha failed validation
        if (this.gRecaptchaResponse.length == 0) {
            this.errorRecaptchaRender = true;
            return false;
        }
        //recaptcha passed validation
        else {
            this.errorRecaptchaRender = false;
            return true;
        }
    }
    resolved(captchaResponse) {
        this.gRecaptchaResponse = `${captchaResponse}`;
    }
    receivePhoneNumber(number) {
        this.enquiryModel.mobile = number;
        this.validatePhoneNumber();
    }
    receivePhoneNumberError(hasError) {
        this.phoneNumberLengthError = hasError;
    }
    validatePhoneNumber() {
        if (this.enquiryModel.mobile == undefined || this.enquiryModel.mobile == null || this.enquiryModel.mobile == '') {
            this.phoneNumberError = true;
        }
        else if (this.enquiryModel.mobile.indexOf('-') == -1 || this.enquiryModel.mobile.split('-')[0] == undefined || this.enquiryModel.mobile.split('-')[0] == null || this.enquiryModel.mobile.split('-')[0] == "") {
            this.phoneCountryCodeError = true;
            this.phoneNumberError = false;
        }
        else if (this.enquiryModel.mobile.split('-')[1] == undefined || this.enquiryModel.mobile.split('-')[1] == null || this.enquiryModel.mobile.split('-')[1] == "") {
            this.phoneNumberError = true;
        }
        else {
            this.phoneNumberError = false;
            this.phoneCountryCodeError = false;
        }
    }
    detectChanges() {
        if (this.cdRef && !this.cdRef.destroyed) {
            this.cdRef.detectChanges();
        }
    }
    getCountryCodes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes').toPromise();
        });
    }
    headerLogoClick() {
        window.open(environment.websiteUrl);
    }
    scrollToElement(elemId) {
        var $container = $('#body'), $scrollTo = $('#' + elemId);
        $("html, body").animate({
            scrollTop: ($scrollTo.offset() ? $scrollTo.offset().top : 0) - $container.offset().top + $container.scrollTop() - 30
        }, "slow");
    }
    socialMediaClickEvent(url) {
        if (!(url.startsWith('https://') || url.startsWith('http://'))) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
    }
    checkIfShowNotebookCourse() {
        this.showNotebookCourse = true;
    }
    redirectToNotebook(url, g) {
        window.location.href = url + "?a=" + this.agentCode + "&board=cbse&class=" + g.name;
    }
    setMindVaultBranding() {
        this.titleService.setTitle('Mindvault Brainiacs Academy');
        this.ejLogoSrc = "assets/media/logos/MVB.png";
    }
    notebookLogin() {
        window.location.href = this.notebookSignInUrl + "?a=" + this.agentCode;
    }
}
